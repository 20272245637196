export default function () {
  if (window.innerWidth <= 1023) {
      window.addEventListener('scroll', handleScroll);
  }

  function handleScroll() {
    const scrollHeight = window.scrollY || document.documentElement.scrollTop;
    const button = document.querySelector('.product__btn-box');

    if (button) {
      if (scrollHeight >= 50) {
        button.classList.add('product__btn-box_sticky');
      } else {
        button.classList.remove('product__btn-box_sticky');
      }
    }
  }
}


