export default function (){

    const themeMetaTag = document.querySelector('meta[name="theme-color"]');

    if (themeMetaTag) {
        themeMetaTag.setAttribute('content', '#fff');
    } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', 'theme-color');
        newMetaTag.setAttribute('content', '#fff');
        document.head.appendChild(newMetaTag);
    }

    const checkboxItems = document.querySelectorAll(".checkbox__item");
    const loaderText = document.querySelector(".loader__percent");
    const steps = document.querySelectorAll(".step");
    const stepLoader = document.querySelector(".step_loader");

    const showNextStep = () => {
        const stepActive = document.querySelector(".step_active");
        const stepNext = steps[Number(stepActive.dataset.id) + 1];

        stepNext.classList.add("step_active");
        stepActive.classList.remove("step_active");
    };

    const progress = () => {
        const interval = 39
        let progressCounter = 0
        let progressInterval

        progressInterval = setInterval(() => {
            if (progressCounter === 100) {
                clearInterval(progressInterval)
                showNextStep()
            } else {
                progressCounter += 1
                loaderText.textContent = String(progressCounter)
            }
        }, interval)
    }

    checkboxItems.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.target.checked = !e.target.checked;

            if (e.target.checked) {
                showNextStep()
            }
        });
    })

    const observer = new MutationObserver(function () {
        const displayNode = stepLoader.currentStyle ? stepLoader.currentStyle.display : getComputedStyle(stepLoader, null).display

        if (displayNode !== 'none') {
            progress();
        }
    });

    observer.observe(stepLoader, {attributes: true, childList: false});
}
