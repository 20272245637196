export default function getDynamicMirrorNameAndLink() {
    const url = new URL(window.location.href);
    const mirrorClassName = document.querySelectorAll('.mirror-name');
    const supportClassName = document.querySelectorAll('.support');
    const privacyPolicyClassName = document.querySelector('.privacy-policy');
    const hostName = url.hostname;
    const supportName = `support@${hostName}`;
    const privacyPolicyNameAndUrl = `https://${hostName}/privacy`;
    const siteUrl = `https://${hostName}`;
    const supportUrl = `mailto:support@${hostName}`

    if (mirrorClassName.length > 0) {
        mirrorClassName.forEach((mirror) => {
            mirror.innerText = hostName;
            mirror.setAttribute('href', siteUrl);
        });
    }

    if (privacyPolicyClassName) {
        privacyPolicyClassName.innerText = privacyPolicyNameAndUrl;
        privacyPolicyClassName.setAttribute ('href', privacyPolicyNameAndUrl);
    }

    if (supportClassName.length > 0) {
        supportClassName.forEach((support) => {
            support.innerText = supportName;
            support.setAttribute('href', supportUrl);
        })
    }
}
