export default function () {
  if (window.innerWidth <= 1023) {
      window.addEventListener('scroll', btnScroll);
  }

  function btnScroll() {
    const targetElement = document.querySelector('.blog');
    const myButton = document.querySelector('.scroll-btn');
    const rect = targetElement?.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const distanceFromTop = rect?.top + scrollTop;

    if (myButton && targetElement) {
      myButton.addEventListener('click', () => {
        window.scrollTo(0, 0);
      })

      if (scrollTop >= distanceFromTop - window.innerHeight) {
          myButton.classList.add('scroll-btn_sticky')
      } else {
        myButton.classList.remove('scroll-btn_sticky')
      }
    }
  }
}



