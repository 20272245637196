export default function () {
  const mirrorName = document.body.dataset.site
  const gtmTag = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  let gtmId = ''

  switch (mirrorName) {
    case 'review_4':
      gtmId = 'GTM-WMW7GSVM'
      break;
    case 'review_5':
      gtmId = 'GTM-TB68RKL3'
      break;
    case 'review_8':
      gtmId = 'GTM-M6XN2JCW'
      break;
    case 'review_9':
      gtmId = 'GTM-K9TSGRKX'
      break;
    case 'review_10':
      gtmId = 'GTM-MDHZPGZQ'
      break;
    case 'review_11':
      gtmId = 'GTM-ND24MZDT'
      break;
    case 'review_12':
      gtmId = 'GTM-5CMWPW4F'
      break;
    case 'review_13':
      gtmId = 'GTM-T83XSBN3'
      break;
    case 'review_14':
      gtmId = 'GTM-PGZT6KGB'
      break;
    case 'review_15':
      gtmId = 'GTM-PCJBTT4X'
      break;
    case 'review_18':
      gtmId = 'GTM-NRVS4QRC'
      break;
    case 'review_19':
      gtmId = 'GTM-KSRSBS8D'
      break;
    case 'review_20':
      gtmId = 'GTM-5VNHQBXV'
      break;
    case 'review_21':
      gtmId = 'GTM-T3WBP52P'
      break;
    case 'review_22':
      gtmId = 'GTM-KK8M8C43';
      break;
    case 'review_23':
      gtmId = 'GTM-568K9TQW';
      break;
    case 'review_24':
      gtmId = 'GTM-KR2NN3M3';
      break;
    case 'review_25':
      gtmId = 'GTM-TRKBH5BQ';
      break;
  }

  if (gtmId) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      document.head.appendChild(j);
    })(window, document, 'script', 'dataLayer', gtmId);

    gtmTag.innerHTML = '';

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";

    gtmTag.appendChild(iframe);

    document.body.prepend(gtmTag)
  }
}
