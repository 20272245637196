export default function () {
    const buttonVisit = document.querySelectorAll('.visit-btn-js')
    const utm = window.location.search


    buttonVisit.forEach(function (button) {
        button.addEventListener('click', function () {
            const newLink = this.href

            this.setAttribute('href',`${newLink}${utm}`)
        })
    })
}