export default function () {
  let menuBtn = document.querySelector('.burger');
  let menu = document.querySelector('.nav');

  menuBtn?.addEventListener('click', function(){
    document.body.classList.toggle('hidden')
    document.getElementsByTagName("html")[0].classList.toggle('hidden');
    menuBtn?.classList.toggle('active');
    menu.classList.toggle('active');
  })

  const navLinks = menu?.querySelectorAll('a')
  navLinks?.forEach(function (link) {
    link?.addEventListener('click', function () {
      document.body.classList.remove('hidden')
      document.getElementsByTagName("html")[0].classList.remove('hidden');
      menuBtn?.classList.remove('active');
      menu?.classList.remove('active');
    })
  })
}
