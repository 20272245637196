export default function () {
    if (window.innerWidth <= 1023) {
        initSlider();
    }

    window.addEventListener("resize", function () {
        checkSlider();
    });

    $('.js-blog-slider').on('beforeChange', function () {
        checkSlider();
    });

    function initSlider() {
        $('.js-blog-slider').not('.slick-initialized').slick({
            //настраиваем слайдер
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            mobileFirst: true,
            infinite: true,
            responsive: [{
                breakpoint: 639,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }]
        });
    }

    function checkSlider() {
        if (window.innerWidth >= 1024 && $('.js-blog-slider').hasClass('slick-initialized')) {
            $('.js-blog-slider').slick('unslick');
        } else if (window.innerWidth <= 1023 && !$('.js-blog-slider').hasClass('slick-initialized')) {
            initSlider();
        }
    }
}


