function getCookie(name) {
  const cookieValue = `; ${document.cookie}`
  const parts = cookieValue.split(`; ${name}=`)

  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
}

function encodeParams(value) {
  const base64Encoded = btoa(value)

  const base64Url = base64Encoded
    .replace(/\+/g, '-')
    .replace(/\//g, '_')

  const padding = base64Url.length % 4

  return padding
    ? base64Url.slice(0, base64Url.length - padding)
    : base64Url
}

export default function () {
  const preparedSearchParams = new URLSearchParams()

  const ga = getCookie('_ga')
  const gaClientId = getCookie('ga_client_id')
  const referer = window.location.hostname

  if (ga) {
    preparedSearchParams.append('_ga', ga)
  }

  if (gaClientId) {
    preparedSearchParams.append('ga_client_id', gaClientId)
  }

  if (referer) {
    preparedSearchParams.append('referer', referer)
  }

  const marketingParams = new URLSearchParams(window.location.search)

  const isGclid = marketingParams.has('gclid')
  const isWbraid = marketingParams.has('wbraid')
  const isGbraid = marketingParams.has('gbraid')

  let googleParams = { title: null, value: null }

  if (isGclid) {
    googleParams = { title: 'gclid', value: marketingParams.get('gclid') }
  } else if (isWbraid) {
    googleParams = { title: 'wbraid', value: marketingParams.get('wbraid') }
  } else if (isGbraid) {
    googleParams = { title: 'gbraid', value: marketingParams.get('gbraid') }
  }

  if (googleParams.title && googleParams.value) {
    preparedSearchParams.append(googleParams.title, googleParams.value)
  }

  for (const [key, value] of marketingParams) {
    if (['campaignid', 'matchtype','keyword'].includes(key)) {
      preparedSearchParams.append(key, value)
    }
  }

  const encryptedCookieParams = encodeParams(preparedSearchParams.toString())

  if (marketingParams || preparedSearchParams) {
    const links = document.querySelectorAll('a');

    links.forEach((link) => {
      let newPath = link.href

      const firstBlock = encryptedCookieParams.substring(0, 50)
      const otherBlocks = encryptedCookieParams.substring(50).match(/.{1,100}/g) || []
      const blocks = [firstBlock, ...otherBlocks]

      blocks.forEach((block, index) => {
        const placeholder = `{sub${index + 1}}`

        newPath = newPath.replace(placeholder, block)
      })

      link.href = newPath
    })
  }
}
