export default function initializeDisclaimers() {
    const state = {
        isDisclaimerVisible: false,
        isCookiePopupVisible: false,
        hostName: new URL(window.location.href).hostname
    };

    const elements = {
        overlay: createOverlay(),
        cookiesBanner: document.querySelector('.cookie-banner'),
        cookiesPopup: document.querySelector('.cookie-popup'),
        disclosureButtons: document.querySelectorAll('.disclosure'),
        scoreButtons: document.querySelectorAll('.score__tooltip')
    };

    function createOverlay() {
        const overlaySites = ['review_18', 'review_19', 'review_20', 'review_21']
        const site = overlaySites.includes(document.body.getAttribute('data-site'))
        if (site) {
            const overlay = document.createElement('div');
            overlay.classList.add('overlay');
            document.body.appendChild(overlay);
            return overlay;
        }
    }

    function createCloseButton() {
        const closeButton = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        closeButton.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        closeButton.setAttribute("width", "10");
        closeButton.setAttribute("height", "10");
        closeButton.setAttribute("viewBox", "0 0 10 10");
        closeButton.setAttribute("fill", "none");
        closeButton.classList.add('close-button');
        closeButton.innerHTML = `
            <path d="M9.49643 1.51267C9.77454 1.23455 9.77454 0.783639 9.49643 0.505525C9.21831 0.22741 8.7674 0.22741 8.48929 0.505525L5.70711 3.2877C5.31658 3.67823 4.68342 3.67823 4.29289 3.2877L1.51071 0.505524C1.2326 0.227409 0.781686 0.22741 0.503571 0.505525C0.225457 0.783639 0.225457 1.23455 0.503572 1.51267L3.28575 4.29485C3.67628 4.68537 3.67627 5.31854 3.28575 5.70906L0.503571 8.49124C0.225456 8.76935 0.225457 9.22027 0.503571 9.49838C0.781686 9.7765 1.2326 9.7765 1.51071 9.49838L4.29289 6.7162C4.68342 6.32568 5.31658 6.32568 5.70711 6.7162L8.48929 9.49838C8.7674 9.7765 9.21831 9.7765 9.49643 9.49838C9.77454 9.22027 9.77454 8.76935 9.49643 8.49124L6.71425 5.70906C6.32372 5.31853 6.32373 4.68537 6.71425 4.29485L9.49643 1.51267Z" fill="#FA437A"/>
        `;
        return closeButton;
    }

    function createDisclaimer(content, className) {
        const disclaimer = document.createElement('div');
        disclaimer.classList.add(className);
        const text = document.createElement('p');
        text.textContent = content;
        const closeButton = createCloseButton();
        disclaimer.appendChild(text);
        disclaimer.appendChild(closeButton);
        return disclaimer;
    }

    function showDisclaimer(parent, content, className) {
        closeAllDisclaimers();
        const disclaimer = createDisclaimer(content, className);
        parent.appendChild(disclaimer);
        showOverlay();
        state.isDisclaimerVisible = true;

        const closeButton = disclaimer.querySelector('.close-button');
        closeButton.addEventListener('click', (event) => {
            event.stopPropagation();
            closeAllDisclaimers();
        });
    }

    function closeAllDisclaimers() {
        document.querySelectorAll('.disclosure__tooltip-disclaimer, .score__tooltip-disclaimer')
            .forEach(disclaimer => disclaimer.remove());
        state.isDisclaimerVisible = false;
        if (!state.isCookiePopupVisible) {
            hideOverlay();
        }
    }

    function showOverlay() {
        elements.overlay?.classList.add('overlay_visible');
    }

    function hideOverlay() {
        elements.overlay?.classList.remove('overlay_visible');
    }

    function initializeDisclosureButtons() {
        elements.disclosureButtons.forEach(button => {
            button.addEventListener('click', (event) => {
                event.stopPropagation();
                const content = `${state.hostName} is a complimentary online resource. We work hard to provide our visitors with useful content and comparison features. Please note that we accept advertising compensation from the companies mentioned on our site, which influences the placement and order of the brands and their products, as well as the ratings they receive. Furthermore, bear in mind that the scores and ratings on the site are also shaped by our subjective opinions, which were created using a methodology that includes our internal analysis of brand market share, reputation, and overall consumer interest. Listing on this site DOES NOT imply endorsement. What is more, we do not cover all providers available on the market. All the representations and warranties regarding the information on this page are disclaimed, except the ones specifically stated in our Terms of Use. Please note that the information on this site, including pricing, may change at any time, so we assume no guarantee for it.`;
                showDisclaimer(button, content, 'disclosure__tooltip-disclaimer');
            });
        });
    }

    function initializeScoreButtons() {
        elements.scoreButtons.forEach(button => {
            button.addEventListener('click', () => {
                const parentScore = button.closest('.score') || button.closest('.column_score');
                if (parentScore) {
                    const content = `This is a ${state.hostName} chosen brand, evaluated through various criteria, including user preferences, feedback, brand recognition, and our comprehensive assessment of the brand's value. This rating is created to guide you in making well-informed purchase choices and finding the best fit for your requirements. To make ${state.hostName} stay free, we receive financial compensation. However, it works only if you decide to use the services of any of the brands featured on our site.`;
                    showDisclaimer(parentScore, content, 'score__tooltip-disclaimer');
                }
            });

            button.addEventListener('touchstart', (event) => {
                event.preventDefault();
                button.click();
            });
        });
    }

    function initializeCookiesBanner() {
        const cookiesBannerButton = elements?.cookiesBanner?.querySelector('.cookie-banner__button');
        const cookiesBannerButtonClose = elements?.cookiesBanner?.querySelector('.cookie-banner__button-close');
        const cookiesPopupButtonClose = elements?.cookiesPopup?.querySelector('.cookie-popup__button-close');

        cookiesBannerButton?.addEventListener('click', () => {
            closeAllDisclaimers();
            elements.cookiesPopup.classList.add('cookie-popup_visible');
            state.isCookiePopupVisible = true;
            showOverlay();
        });

        cookiesPopupButtonClose?.addEventListener('click', () => {
            elements.cookiesPopup.classList.remove('cookie-popup_visible');
            state.isCookiePopupVisible = false;
            hideOverlay();
        });

        cookiesBannerButtonClose?.addEventListener('click', () => {
            elements.cookiesBanner.style.display = 'none';
        });
    }

    function initializeGlobalListeners() {
        document.addEventListener('click', (event) => {
            if (!event.target.closest('.disclosure__tooltip-disclaimer') &&
                !event.target.closest('.score__tooltip-disclaimer') &&
                !event.target.closest('.disclosure') &&
                !event.target.closest('.score__tooltip')) {
                closeAllDisclaimers();
            }
        });

        elements.overlay?.addEventListener('click', () => {
            closeAllDisclaimers();
            if (state.isCookiePopupVisible) {
                elements.cookiesPopup.classList.remove('cookie-popup_visible');
                state.isCookiePopupVisible = false;
            }
            hideOverlay();
        });
    }

    initializeDisclosureButtons();
    initializeScoreButtons();
    initializeCookiesBanner();
    initializeGlobalListeners();
}
