export default circleProgressRate => {
    const circles = document.querySelectorAll('.progress-circle-fill')

    circles.forEach((circle) => {
        const targetPercentage = parseInt(circle.getAttribute('data-percent'));

        function updateProgress(circle, targetPercentage, duration = 1000) {
            const startTime = performance.now();
            const initialOffset = parseFloat(circle.style.strokeDashoffset || 201.06);
            const targetOffset = 201.06 - (201.06 * targetPercentage) / 100;

            function animate(currentTime) {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
                const currentOffset = initialOffset + (targetOffset - initialOffset) * progress;

                circle.style.strokeDashoffset = currentOffset.toString();

                if (progress < 1) {
                    requestAnimationFrame(animate);
                }
            }

            requestAnimationFrame(animate);
        }

        updateProgress(circle, targetPercentage, 2000);
    })
}