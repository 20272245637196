export default function () {
    const productTooltip = document.querySelectorAll('.catalog__tooltip')
    const popupTooltip = document.querySelector('.popup-tooltip')
    const closeTooltip = document.querySelector('.popup-tooltip__close')
    const overlayTooltip = document.querySelector('.popup-tooltip__overlay')

    productTooltip.forEach(function (tooltip) {
        tooltip.addEventListener('click', function () {
            overlayTooltip.style.display = 'block';
            popupTooltip.style.display = 'block';
        })
    })

    closeTooltip?.addEventListener('click', hidePopup)
    overlayTooltip?.addEventListener('click', hidePopup)

    function hidePopup() {
        overlayTooltip.style.display = 'none';
        popupTooltip.style.display = 'none';
    }
}