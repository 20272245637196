export default function () {

    function blogAccordion() {
        const accordionArray = document.querySelectorAll('.blog-accordion');
        const accordionContentArray = document.querySelectorAll('.blog-accordion__content');
        const accordionTextArray = document.querySelectorAll('.blog-accordion__text');

        if (accordionArray.length) {

            for (let i = 0; i < accordionArray.length; i++) {
                accordionArray[i].addEventListener('click', () => {
                    const offsetHeight = accordionTextArray[i].offsetHeight

                    if (accordionArray[i].classList.contains('blog-accordion_opened')) {
                        accordionArray[i].classList.remove('blog-accordion_opened')
                        accordionContentArray[i].style.height = '';
                    } else {
                        accordionArray.forEach((item) => {
                            item.classList.remove('blog-accordion_opened')
                            item.querySelector('.blog-accordion__content').style.height = '';
                        })

                        accordionArray[i].classList.add('blog-accordion_opened')
                        accordionContentArray[i].style.height = `${offsetHeight}px`
                    }
                })
            }
        }
    }

    blogAccordion()
}
