export default function () {

    $('.rate-slider').slick({
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        infinite: true,
        arrows: false,
        dots: false,
        speed: 3000,
        autoplay: true,
        responsive: [{
            breakpoint: 640,
            settings: {
                autoplay: true,
                autoplaySpeed: 0,
                speed: 3000,
                pauseOnHover: true,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 1219,
            settings: {
                autoplay: true,
                autoplaySpeed: 0,
                speed: 3000,
                pauseOnHover: true,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }]
    });

    const rateSlider = $(".rate-girls__slider");
    rateSlider.on('init', function (event, slick) {

        let cur = $(slick.$slides[slick.currentSlide]);

        let next = cur.next();
        let prev = cur.prev();

        let next2 = cur.next().next();
        let prev2 = cur.prev().prev();

        let next3 = cur.next().next().next();
        let prev3 = cur.prev().prev().prev();

        prev.addClass('rate-prev');
        next.addClass('rate-next');

        prev2.addClass('rate-prev2');
        next2.addClass('rate-next2');

        prev3.addClass('rate-prev3');
        next3.addClass('rate-next3');

    }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if(currentSlide === 1){

            $('.rate-girls__dislike').on( "click", function() {
                $('.slick-current .rate-like__last').fadeIn(600)
            });
        }

        let cur = $(slick.$slides[nextSlide]);
        let slides = $(slick.$slides)

        slides
            .removeClass('rate-next')
            .removeClass('rate-prev')
            .removeClass('rate-next2')
            .removeClass('rate-prev2')
            .removeClass('rate-next3')
            .removeClass('rate-prev3')

        cur.next().next().next().addClass('rate-next3')
        cur.next().next().addClass('rate-next2')
        cur.next().addClass('rate-next')

        cur.prev().prev().prev().addClass('rate-prev3')
        cur.prev().prev().addClass('rate-prev2')
        cur.prev().addClass('rate-prev')
    })

    rateSlider.slick({
        speed: 700,
        mobileFirst: true,
        initialSlide: 9,
        variableWidth: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        draggable: false,
        swipe: false,
        touchMove: false,
        swipeToSlide: false,
        infinite: false,
        prevArrow: $('.btn-prev'),
    });

    $( ".rate-girls__like" ).on( "click", function() {
        $('.slick-current .rate-like__bg').fadeIn(600)
    });

    $( ".rate-like__back" ).on( "click", function() {
        $('.rate-like__bg').fadeOut(0)
    });

    const currentPathName = window.location.pathname;


    const asianArr=[
        {name:"Orchidromance",link:"https://orchidromance.com/land/sp/40171b46", className: 'site-orchidromance',},
        {name:"LovingFeel",link:"https://lovingfeel.com/land/sp/39326a4f", className: 'site-lovingfeel',},
        {name:"AsianMelodies",link:"https://asianmelodies.com/land/sp/20598710", className: 'site-Asianmelodies',},
        {name:"philitalks",link:"https://philitalks.com/land/sp/49fd708d", className: 'site-PhiliTalks',},
        {name:"loverwhirl",link:"https://loverwhirl.com/land/sp/214c733b", className: 'site-loverwhirl',},];

    const slavicArr=[
        {name:"Bravodate",link:"https://bravodate.com/land/sp/6ee4935b", className: 'site-bravodate',},
        {name:"JollyRomance",link:"https://jollyromance.com/land/sp/b435e6f4", className: 'site-Jollyromance',},
        {name: "LoveSwans", link: "https://loveswans.com/land/sp/d2004d28", className: 'site-loveswans',},
        {name:"AmourFeel",link:"https://amourfeel.com/land/sp/263fb41c", className: 'site-Amourfeel',},
        {name:"Rondevo",link:"https://rondevo.com/land/sp/0fe765b5", className: 'site-rondevo',},
    ];
    const latinaArr=[
        {name:"La-date",link:"https://la-date.com/land/sp/1f43b0af", className: 'site-Ladate',},
        {name:"LoveFort",link:"https://lovefort.com/land/sp/7f3e0941", className: 'site-Lovefort',},
        {name:"BestDates",link:"https://bestdates.com/land/sp/91bf1dc3", className: 'site-bestdates',},
        {name:"Funchatt",link:"https://funchatt.com/land/sp/b4b4d065", className: 'site-Funchatt',},
        {name:"LatamVibe",link:"https://latamvibe.com/land/sp/37f88378", className: 'site-latamvibe',},
    ];
    function setProduct(namePages, arr) {
        const randomItem = arr[Math.floor(Math.random() * arr.length)];

        const pages = document.querySelector(`.rate-like__last--${namePages}`)
        const rateImg = pages.querySelector('.rate-like__logo')
        const rateLogo = pages.querySelector('.rate-like__text span')
        const rateLink = pages.querySelector('.rate-like__chat')

        rateImg.setAttribute('src', `img/${randomItem.name}.svg`)
        rateLogo.textContent = randomItem.name
        rateLink.setAttribute('href', `${randomItem.link}`)
        rateLink.classList.add(`${randomItem.className}`)
    }

    switch (currentPathName) {
        case '/asian-rate.html':
            setProduct('asian', asianArr)
            $('.rate-btn').css('pointer-events', 'none')
            break;

        case '/slavic-rate.html':
            setProduct('slavic', slavicArr)
            $('.rate-btn').css('pointer-events', 'none')
            break;

        case '/latina-rate.html':
            setProduct('latina', latinaArr)
            $('.rate-btn').css('pointer-events', 'none')
            break;
    }
}
