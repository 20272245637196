export default function () {
  const dateParagraph = document.querySelector('.update-date__text');
  const blogPostUpdate = document.querySelectorAll('.post__date')
  const formattedDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long' });

  if (dateParagraph) {
    dateParagraph.innerText = `Updated for ${formattedDate}`;
  }

  if (blogPostUpdate) {
    blogPostUpdate.forEach((item) => {
       item.innerText = formattedDate
    })
  }
}
