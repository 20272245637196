export default function () {
  const params = new URLSearchParams(window.location.search)
  const keyword = params.get('keyword')
  const path = window.location.pathname
  const site = document.body.getAttribute('data-site') || 'review_1'
  const isMaleSite = site === 'review_21'

  const ethnics = {
    0: 'International',
    1: 'European',
    2: 'Slavic',
    3: 'Asian',
    4: 'Latin'
  }

  const KEYWORDS = {
    0: [
      {
        key: 'international',
        text: 'International'
      },
      {
        key: 'overseas',
        text: 'International'
      },
      {
        key: 'interracial',
        text: 'International'
      },
      {
        key: 'dutch',
        text: 'Dutch'
      },
      {
        key: 'german',
        text: 'German'
      },
      {
        key: 'french',
        text: 'French'
      },
      {
        key: 'france',
        text: 'French'
      },
      {
        key: 'italy',
        text: 'Italian'
      },
      {
        key: 'italian',
        text: 'Italian'
      },
      {
        key: 'norway',
        text: 'Norwegian'
      },
      {
        key: 'norwegian',
        text: 'Norwegian'
      },
      {
        key: 'spain',
        text: 'Spanish'
      },
      {
        key: 'spanish',
        text: 'Spanish'
      },
      {
        key: 'portugal',
        text: 'Portuguese'
      },
      {
        key: 'portuguese',
        text: 'Portuguese'
      },
      {
        key: 'britain',
        text: 'British'
      },
      {
        key: 'british',
        text: 'British'
      },
      {
        key: 'greek',
        text: 'Greek'
      },
      {
        key: 'greece',
        text: 'Greek'
      },
      {
        key: 'poland',
        text: 'Polish',
      },
      {
        key: 'polish',
        text: 'Polish',
      },
      {
        key: 'ukraine',
        text: 'Ukrainian',
      },
      {
        key: 'ukrainian',
        text: 'Ukrainian',
      },
      {
        key: 'belarus',
        text: 'Belarusian',
      },
      {
        key: 'russia',
        text: 'Russian',
      },
      {
        key: 'kazakh',
        text: 'Kazakh',
      },
      {
        key: 'armenia',
        text: 'Armenian',
      },
      {
        key: 'georgia',
        text: 'Georgian',
      },
      {
        key: 'romania',
        text: 'Romanian',
      },
      {
        key: 'uzbek',
        text: 'Uzbek',
      },
      {
        key: 'turkmen',
        text: 'Turkmen',
      },
      {
        key: 'azerbaijan',
        text: 'Azerbaijani',
      },
      {
        key: 'moldavia',
        text: 'Moldavian',
      },
      {
        key: 'latvia',
        text: 'Latvian',
      },
      {
        key: 'lithuania',
        text: 'Lithuanian',
      },
      {
        key: 'estonia',
        text: 'Estonian',
      },
      {
        key: 'bulgaria',
        text: 'Bulgarian',
      },
      {
        key: 'slovak',
        text: 'Slovakian',
      },
      {
        key: 'slovenia',
        text: 'Slovenian',
      },
      {
        key: 'bosnia',
        text: 'Bosnian',
      },
      {
        key: 'czech',
        text: 'Czech',
      },
      {
        key: 'japan',
        text: 'Japanese',
      },
      {
        key: 'china',
        text: 'Chinese',
      },
      {
        key: 'chinese',
        text: 'Chinese',
      },
      {
        key: 'cambodia',
        text: 'Cambodian',
      },
      {
        key: 'malaysia',
        text: 'Malaysian',
      },
      {
        key: 'hong kong',
        text: 'Hong Kong',
      },
      {
        key: 'korea',
        text: 'Korean',
      },
      {
        key: 'pina',
        text: 'Filipino',
      },
      {
        key: 'filipino',
        text: 'Filipino',
      },
      {
        key: 'thai',
        text: 'Thai',
      },
      {
        key: 'vietnam',
        text: 'Vietnam',
      },
      {
        key: 'viet',
        text: 'Vietnam',
      },
      {
        key: 'seoul',
        text: 'Korean',
      },
      {
        key: 'tokyo',
        text: 'Japanese',
      },
      {
        key: 'indonesia',
        text: 'Indonesian',
      },
      {
        key: 'singapore',
        text: 'Singaporean',
      },
      {
        key: 'argentina',
        text: 'Argentinian',
      },
      {
        key: 'argentinian',
        text: 'Argentinian',
      },
      {
        key: 'colombia',
        text: 'Colombian',
      },
      {
        key: 'brazil',
        text: 'Brazilian',
      },
      {
        key: 'mexica',
        text: 'Mexican',
      },
      {
        key: 'mexico',
        text: 'Mexican',
      },
      {
        key: 'venezuela',
        text: 'Venezuelan',
      },
      {
        key: 'costa rica',
        text: 'Costa Rican',
      },
      {
        key: 'puerto rico',
        text: 'Puerto Rican',
      },
      {
        key: 'puerto rican',
        text: 'Puerto Rican',
      },
      {
        key: 'dominican',
        text: 'Dominican',
      },
      {
        key: 'haiti',
        text: 'Haitian',
      },
      {
        key: 'salvador',
        text: 'Salvadoran',
      },
      {
        key: 'bolivia',
        text: 'Bolivian',
      },
      {
        key: 'chile',
        text: 'Chilean',
      },
      {
        key: 'peru',
        text: 'Peruvian',
      },
      {
        key: 'guyana',
        text: 'Guyanese',
      },
      {
        key: 'guyanese',
        text: 'Guyanese',
      },
      {
        key: 'guatemala',
        text: 'Guatemalan',
      },
      {
        key: 'honduras',
        text: 'Honduran',
      },
      {
        key: 'honduran',
        text: 'Honduran',
      },
      {
        key: 'cuba',
        text: 'Cuban',
      },
      {
        key: 'cuban',
        text: 'Cuban',
      },
    ],
    1: [
      {
        key: 'dutch',
        text: 'Dutch',
      },
      {
        key: 'german',
        text: 'German',
      },
      {
        key: 'french',
        text: 'French',
      },
      {
        key: 'france',
        text: 'French',
      },
      {
        key: 'italy',
        text: 'Italian',
      },
      {
        key: 'italian',
        text: 'Italian',
      },
      {
        key: 'norway',
        text: 'Norwegian',
      },
      {
        key: 'norwegian',
        text: 'Norwegian',
      },
      {
        key: 'spain',
        text: 'Spanish',
      },
      {
        key: 'spanish',
        text: 'Spanish',
      },
      {
        key: 'portugal',
        text: 'Portuguese',
      },
      {
        key: 'portuguese',
        text: 'Portuguese',
      },
      {
        key: 'britain',
        text: 'British',
      },
      {
        key: 'british',
        text: 'British',
      },
      {
        key: 'greek',
        text: 'Greek',
      },
      {
        key: 'greece',
        text: 'Greek',
      },
      {
        key: 'swedish',
        text: 'Swedish',
      },
      {
        key: 'european',
        text: 'European'
      },
      {
        key: 'cis',
        text: 'European'
      },
      {
        key: 'paris',
        text: 'French',
      },
      {
        key: 'deutsch',
        text: 'European',
      },
      {
        key: 'munich',
        text: 'German',
      },
      {
        key: 'icelandic',
        text: 'Icelandic',
      },
      {
        key: 'iceland',
        text: 'Icelandic',
      },
      {
        key: 'ireland',
        text: 'Irish',
      },
      {
        key: 'irish',
        text: 'Irish',
      },
      {
        key: 'united kingdom',
        text: 'British',
      },
      {
        key: 'london',
        text: 'British',
      },
      {
        key: 'english',
        text: 'English',
      },
      {
        key: 'milan',
        text: 'Italian',
      },
      {
        key: 'valencia',
        text: 'Spanish',
      },
      {
        key: 'barcelona',
        text: 'Spanish',
      },
      {
        key: 'madrid',
        text: 'Madrid',
      },
      {
        key: 'malaga',
        text: 'Malaga',
      },
      {
        key: 'marbella',
        text: 'Marbella',
      },
      {
        key: 'lisbon',
        text: 'Lisbon',
      },
      {
        key: 'finnland',
        text: 'Finnish',
      },
      {
        key: 'finnish',
        text: 'Finnish',
      },
      {
        key: 'nethrlands',
        text: 'Dutch',
      },
      {
        key: 'netherlands',
        text: 'Dutch',
      },
      {
        key: 'amsterdam',
        text: 'Dutch',
      },
      {
        key: 'sweden',
        text: 'Sweden',
      },
      {
        key: 'swedish',
        text: 'Swedish',
      },
      {
        key: 'scandinavian',
        text: 'Scandinavian',
      },
      {
        key: 'scottish',
        text: 'Scottish',
      },
      {
        key: 'danish',
        text: 'Danish',
      },
      {
        key: 'switzerland',
        text: 'Swiss',
      },
      {
        key: 'scotland',
        text: 'Scottish',
      },
      {
        key: 'cyprus',
        text: 'Cyprus',
      },
      {
        key: 'albanian',
        text: 'Albanian',
      },
      {
        key: 'alban',
        text: 'Albanian',
      },
      {
        key: 'belgium',
        text: 'Belgian',
      },
      {
        key: 'austria',
        text: 'Austrian',
      },
      {
        key: 'swiss',
        text: 'Swiss',
      },
      {
        key: 'vienna',
        text: 'Austrian',
      },
      {
        key: 'euro',
        text: 'European',
      },
      {
        key: 'malta',
        text: 'European',
      },
      {
        key: 'tenerife',
        text: 'Spanish',
      },
      {
        key: 'luxembourg',
        text: 'European',
      },
      {
        key: 'nordic',
        text: 'Scandinavian',
      },
      {
        key: 'danmark',
        text: 'European',
      },
      {
        key: 'denmark',
        text: 'European',
      },
    ],
    2: [
      {
        key: 'czech',
        text: 'Czech',
      },
      {
        key: 'slav',
        text: 'Slavic',
      },
      {
        key: 'ukr',
        text: 'Ukrainian',
      },
      {
        key: 'kiyv',
        text: 'Ukrainian',
      },
      {
        key: 'poland',
        text: 'Polish',
      },
      {
        key: 'polish',
        text: 'Polish',
      },
      {
        key: 'ukraine',
        text: 'Ukrainian',
      },
      {
        key: 'ukrainian',
        text: 'Ukrainian',
      },
      {
        key: 'belarus',
        text: 'Belarusian',
      },
      {
        key: 'russia',
        text: 'Russian',
      },
      {
        key: 'kazakh',
        text: 'Kazakh',
      },
      {
        key: 'armenia',
        text: 'Armenian',
      },
      {
        key: 'georgia',
        text: 'Georgian',
      },
      {
        key: 'romania',
        text: 'Romanian',
      },
      {
        key: 'uzbek',
        text: 'Uzbek',
      },
      {
        key: 'turkmen',
        text: 'Turkmen',
      },
      {
        key: 'azerbaijan',
        text: 'Azerbaijani',
      },
      {
        key: 'moldavia',
        text: 'Moldavian',
      },
      {
        key: 'latvia',
        text: 'Latvian',
      },
      {
        key: 'lithuania',
        text: 'Lithuanian',
      },
      {
        key: 'estonia',
        text: 'Estonian',
      },
      {
        key: 'bulgaria',
        text: 'Bulgarian',
      },
      {
        key: 'slovak',
        text: 'Slovakian',
      },
      {
        key: 'slovenia',
        text: 'Slovenian',
      },
      {
        key: 'bosnia',
        text: 'Bosnian',
      },
      {
        key: 'odessa',
        text: 'Ukrainian',
      },
      {
        key: 'kharkiv',
        text: 'Ukrainian',
      },
      {
        key: 'lviv',
        text: 'Ukrainian',
      },
      {
        key: 'ucrain',
        text: 'Ukrainian',
      },
      {
        key: 'moldovian',
        text: 'Moldavian',
      },
      {
        key: 'moldova',
        text: 'Moldavian',
      },
      {
        key: 'moldovan',
        text: 'Moldavian',
      },
      {
        key: 'moldovanian',
        text: 'Moldavian',
      },
      {
        key: 'krakow',
        text: 'Czech',
      },
      {
        key: 'warsaw',
        text: 'Polish',
      },
      {
        key: 'poznan',
        text: 'Polish',
      },
      {
        key: 'polski',
        text: 'Polish',
      },
      {
        key: 'polsku',
        text: 'Polish',
      },
      {
        key: 'chech',
        text: 'Czech',
      },
      {
        key: 'chec',
        text: 'Czech',
      },
      {
        key: 'cz',
        text: 'Czech',
      },
      {
        key: 'czechoslovakia',
        text: 'Czech',
      },
      {
        key: 'prague',
        text: 'Czech',
      },
      {
        key: 'belarusian',
        text: 'Belarusian',
      },
      {
        key: 'belarussian',
        text: 'Belarusian',
      },
      {
        key: 'baltic',
        text: 'Baltic',
      },
      {
        key: 'latvian',
        text: 'Latvian',
      },
      {
        key: 'lithua',
        text: 'Lithuanian',
      },
      {
        key: 'riga',
        text: 'Latvian',
      },
      {
        key: 'estonian',
        text: 'Estonian',
      },
      {
        key: 'tallinn',
        text: 'Estonian',
      },
      {
        key: 'russian',
        text: 'Russian',
      },
      {
        key: 'russ',
        text: 'Russian',
      },
      {
        key: 'rusha',
        text: 'Russian',
      },
      {
        key: 'petersburg',
        text: 'Russian',
      },
      {
        key: 'moscow',
        text: 'Russian',
      },
      {
        key: 'ruasian',
        text: 'Russian',
      },
      {
        key: 'armenian',
        text: 'Armenian',
      },
      {
        key: 'georgian',
        text: 'Georgian',
      },
      {
        key: 'greek',
        text: 'Greek',
      },
      {
        key: 'greece',
        text: 'Greek',
      },
      {
        key: 'bulgarian',
        text: 'Bulgarian',
      },
      {
        key: 'croatia',
        text: 'Croatian',
      },
      {
        key: 'croatan',
        text: 'Croatian',
      },
      {
        key: 'croatian',
        text: 'croatian',
      },
      {
        key: 'croatian',
        text: 'Czech',
      },
      {
        key: 'caucasian',
        text: 'Caucasian',
      },
      {
        key: 'kazakhstan',
        text: 'Kazakh',
      },
      {
        key: 'sloven',
        text: 'Slovenian',
      },
      {
        key: 'hungary',
        text: 'Hungarian',
      },
      {
        key: 'hungarian',
        text: 'Hungarian',
      },
      {
        key: 'budapest',
        text: 'Hungarian',
      },
      {
        key: 'romanian',
        text: 'Romanian',
      },
      {
        key: 'bucharest',
        text: 'Romanian',
      },
    ],
    3: [
      {
        key: 'japan',
        text: 'Japanese',
      },
      {
        key: 'china',
        text: 'Chinese',
      },
      {
        key: 'chinese',
        text: 'Chinese',
      },
      {
        key: 'cambodia',
        text: 'Cambodian',
      },
      {
        key: 'malaysia',
        text: 'Malaysian',
      },
      {
        key: 'hong kong',
        text: 'Hong Kong',
      },
      {
        key: 'korea',
        text: 'Korean',
      },
      {
        key: 'pina',
        text: 'Filipino',
      },
      {
        key: 'filipino',
        text: 'Filipino',
      },
      {
        key: 'thai',
        text: 'Thai',
      },
      {
        key: 'vietnam',
        text: 'Vietnam',
      },
      {
        key: 'viet',
        text: 'Vietnam',
      },
      {
        key: 'seoul',
        text: 'Korean',
      },
      {
        key: 'tokyo',
        text: 'Japanese',
      },
      {
        key: 'indonesia',
        text: 'Indonesian',
      },
      {
        key: 'singapore',
        text: 'Singaporean',
      },
      {
        key: 'asian',
        text: 'Asian',
      },
      {
        key: 'from asia',
        text: 'Asian',
      },
      {
        key: 'asain',
        text: 'Asian',
      },
      {
        key: 'asien',
        text: 'Asian',
      },
      {
        key: 'mandarin',
        text: 'Asian',
      },
      {
        key: 'shanghai',
        text: 'Asian',
      },
      {
        key: 'beijing',
        text: 'Asian',
      },
      {
        key: 'japanese',
        text: 'Japanese',
      },
      {
        key: 'jap',
        text: 'Japanese',
      },
      {
        key: 'korean',
        text: 'Korean',
      },
      {
        key: 'south korea',
        text: 'Korean',
      },
      {
        key: 'taiwan',
        text: 'Taiwanese',
      },
      {
        key: 'taiwanese',
        text: 'Taiwanese',
      },
      {
        key: 'thailad',
        text: 'Thai',
      },
      {
        key: 'pattaya',
        text: 'Thai',
      },
      {
        key: 'phuket',
        text: 'Thai',
      },
      {
        key: 'bangkok',
        text: 'Thai',
      },
      {
        key: 'phillipines',
        text: 'Filipino',
      },
      {
        key: 'filipines',
        text: 'Filipino',
      },
      {
        key: 'pinoy',
        text: 'Filipino',
      },
      {
        key: 'cebu',
        text: 'Asian',
      },
      {
        key: 'filipina',
        text: 'Filipino',
      },
      {
        key: 'phillipina',
        text: 'Filipino',
      },
      {
        key: 'philla',
        text: 'Filipino',
      },
      {
        key: 'philippine',
        text: 'Filipino',
      },
      {
        key: 'philipines',
        text: 'Filipino',
      },
      {
        key: 'philipino4u',
        text: 'Filipino',
      },
      {
        key: 'philipino',
        text: 'Filipino',
      },
      {
        key: 'philippines',
        text: 'Filipino',
      },
      {
        key: 'manila',
        text: 'Asian',
      },
      {
        key: 'indonesian',
        text: 'indonesian',
      },
      {
        key: 'bali',
        text: 'Asian',
      },
      {
        key: 'myanmar',
        text: 'Asian',
      },
      {
        key: 'cambodian',
        text: 'Cambodian',
      },
      {
        key: 'malaysian',
        text: 'Malaysian',
      },
      {
        key: 'cirebon',
        text: 'Asian',
      },
      {
        key: 'demak',
        text: 'Asian',
      },
      {
        key: 'denpasar',
        text: 'Asian',
      },
      {
        key: 'depok',
        text: 'Asian',
      },
      {
        key: 'jember',
        text: 'Asian',
      },
      {
        key: 'lhokseumawe',
        text: 'Asian',
      },
      {
        key: 'lumajang',
        text: 'Asian',
      },
      {
        key: 'madiun',
        text: 'Asian',
      },
      {
        key: 'padang',
        text: 'Asian',
      },
      {
        key: 'medan',
        text: 'Asian',
      },
      {
        key: 'melbourne',
        text: 'Asian',
      },
      {
        key: 'merauke',
        text: 'Asian',
      },
      {
        key: 'mojokerto',
        text: 'Indonesian',
      },
    ],
    4: [
      {
        key: 'argentina',
        text: 'Argentinian',
      },
      {
        key: 'argentinian',
        text: 'Argentinian',
      },
      {
        key: 'colombia',
        text: 'Colombian',
      },
      {
        key: 'brazil',
        text: 'Brazilian',
      },
      {
        key: 'brazilian',
        text: 'Brazilian',
      },
      {
        key: 'mexica',
        text: 'Mexican',
      },
      {
        key: 'mexico',
        text: 'Mexican',
      },
      {
        key: 'venezuela',
        text: 'Venezuelan',
      },
      {
        key: 'costa rica',
        text: 'Costa Rican',
      },
      {
        key: 'puerto rico',
        text: 'Puerto Rican',
      },
      {
        key: 'puerto rican',
        text: 'Puerto Rican',
      },
      {
        key: 'dominican',
        text: 'Dominican',
      },
      {
        key: 'haiti',
        text: 'Haitian',
      },
      {
        key: 'salvador',
        text: 'Salvadoran',
      },
      {
        key: 'bolivia',
        text: 'Bolivian',
      },
      {
        key: 'chile',
        text: 'Chilean',
      },
      {
        key: 'peru',
        text: 'Peruvian',
      },
      {
        key: 'guyana',
        text: 'Guyanese',
      },
      {
        key: 'guyanese',
        text: 'Guyanese',
      },
      {
        key: 'guatemala',
        text: 'Guatemalan',
      },
      {
        key: 'honduras',
        text: 'Honduran',
      },
      {
        key: 'honduran',
        text: 'Honduran',
      },
      {
        key: 'cuba',
        text: 'Cuban',
      },
      {
        key: 'cuban',
        text: 'Cuban',
      },
      {
        key: 'latinas',
        text: 'Latin',
      },
      {
        key: 'latino',
        text: 'Latin',
      },
      {
        key: 'brazilian',
        text: 'Brazilian',
      },
      {
        key: 'rio de janeiro',
        text: 'Brazilian',
      },
      {
        key: 'janeiro',
        text: 'Brazilian',
      },
      {
        key: 'brasil',
        text: 'Brazilian',
      },
      {
        key: 'colombian',
        text: 'Colombian',
      },
      {
        key: 'medelin',
        text: 'Colombian',
      },
      {
        key: 'dominican republic',
        text: 'Dominican',
      },
      {
        key: 'dominica',
        text: 'Dominican',
      },
      {
        key: 'dominicana',
        text: 'Dominican',
      },
      {
        key: 'dominicano',
        text: 'Dominican',
      },
      {
        key: 'mexican',
        text: 'Mexican',
      },
      {
        key: 'mexic',
        text: 'Mexican',
      },
      {
        key: 'puerto',
        text: 'Puerto Rican',
      },
      {
        key: 'puert',
        text: 'Puerto Rican',
      },
      {
        key: 'rico',
        text: 'Puerto Rican',
      },
      {
        key: 'puerta',
        text: 'Puerto Rican',
      },
      {
        key: 'argntina',
        text: 'Argentinian',
      },
      {
        key: 'venezuelan',
        text: 'Venezuelan',
      },
      {
        key: 'haitian',
        text: 'Haitian',
      },
      {
        key: 'jamaica',
        text: 'Jamaican',
      },
      {
        key: 'bolivian',
        text: 'Bolivian',
      },
      {
        key: 'ecuador',
        text: 'Ecuadorian',
      },
      {
        key: 'trinidad',
        text: 'Caribbean',
      },
      {
        key: 'guatemalan',
        text: 'Guatemalan',
      },
      {
        key: 'belize',
        text: 'Belizean',
      },
      {
        key: 'barbados',
        text: 'Barbadians',
      },
      {
        key: 'hawaii',
        text: 'Hawaiian',
      },
      {
        key: 'costa rican',
        text: 'Costa Rican',
      },
      {
        key: 'panama',
        text: 'Panamanian',
      },
      {
        key: 'peruvian',
        text: 'Peruvian',
      },
      {
        key: 'puerto-rican',
        text: 'Puerto Rican',
      }
    ],
  }
  const PERSONS = [
    {
      key: 'girlfriend',
      text: 'Girls',
    },
    {
      key: 'girl',
      text: 'Girls',
    },
    {
      key: 'lady',
      text: 'Ladies',
    },
    {
      key: 'ladies',
      text: 'Ladies',
    },
    {
      key: 'penpal',
      text: 'Pen Pals',
    },
    {
      key: 'pen pal',
      text: 'Pen Pals',
    },
    {
      key: 'singles',
      text: 'Singles',
    },
    {
      key: 'lover',
      text: 'Lover',
    },
    {
      key: 'boyfriend',
      text: 'Boyfriend',
    },
    {
      key: 'boy',
      text: 'Boys',
    },
    {
      key: 'fellow',
      text: 'Fellow',
    },
    {
      key: 'guy',
      text: 'Guy',
    },
    {
      key: 'man',
      text: 'Men',
    },
    {
      key: 'men',
      text: 'Men',
    },
    {
      key: 'woman',
      text: 'Women',
    },
    {
      key: 'women',
      text: 'Women',
    },
    {
      key: 'gay',
      text: 'Gay',
    },
    {
      key: 'tranny',
      text: 'Transsexuals',
    },
    {
      key: 'ts',
      text: 'Transsexuals',
    },
    {
      key: 'transgender',
      text: 'Transsexuals',
    },
    {
      key: 'bi',
      text: 'Bisexuals',
    },
    {
      key: 'bisexual',
      text: 'Bisexuals',
    },
  ]
  const PRODUCT = [
    {
      key: 'app',
      text: 'Dating Apps',
    },
    {
      key: 'site',
      text: 'Dating Sites',
    },
  ]
  const ACTIONS = [
    {
      key: 'meet',
      text: 'Meet',
    },
    {
      key: 'find',
      text: 'Find',
    },
    {
      key: 'date',
      text: 'Meet',
    },
    {
      key: 'talk',
      text: 'Talk',
    },
    {
      key: 'chat',
      text: 'Chat',
    },
  ]

  let ethnic = ethnics[0]
  let subEthnic = KEYWORDS[0].text
  let person = 'Women'
  let malePerson = "Men"
  let product = 'Dating Sites'
  let action = 'Find'

  const setEthnics = () => {
    const pathItems = path.split('/');
    const isHomePage = path.includes('index') || path === '/';

    let pathEthnic,
      pathEthnicKey,
      pathSubEthnicEthnic,
      keywordSubethnic,
      pathSubEthnic,
      pathSubEthnicKey,
      keywordSubethnicEthnic,
      pageSubethnic;

    if (pathItems.length > 2) {
      pageSubethnic = pathItems[pathItems.length - 1].split('.')[0]
    }

    Object.entries(ethnics).forEach(([key, value]) => {

      if (path.includes(value.toLowerCase())) {
        pathEthnic = value;
        pathEthnicKey = key;
      }
    })

    for (let key in KEYWORDS) {

      Object.entries(KEYWORDS[key]).forEach(([kw, value]) => {

        if (pageSubethnic && pageSubethnic === value.key) {
          pathSubEthnic = value.text
          pathSubEthnicKey = key
          pathSubEthnicEthnic = ethnics[key]
        }

        if (keyword && keyword.includes(value.key)) {
          keywordSubethnic = value.text
          keywordSubethnicEthnic = ethnics[key]
        }
      })
    }

    if (isHomePage) {
      ethnic = ethnics[0]

      if (keywordSubethnic) {
        subEthnic = keywordSubethnic
        ethnic = keywordSubethnicEthnic
      }
    }

    if (pathEthnic) {
      ethnic = pathEthnic
    }

    if (keywordSubethnic) {
      const isEuropeanOrSlavicPage = keywordSubethnicEthnic === pathEthnic
        || (keywordSubethnicEthnic === ethnics[1] && pathEthnic === ethnics[2])
      const isEuropeanKeyOnSlavicPage = pathEthnic === ethnics[2] && keywordSubethnicEthnic === ethnics[1]

      if (isEuropeanOrSlavicPage) {
        ethnic = keywordSubethnicEthnic
        subEthnic = keywordSubethnic
      }

      if (isEuropeanKeyOnSlavicPage) {
        ethnic = keywordSubethnicEthnic
      }
    }

    if (pathSubEthnic) {
      ethnic = pathSubEthnicEthnic
      subEthnic = pathSubEthnic

      if (pathSubEthnic !== pathEthnic) {
        const isEuropeanSubethnicPage = ethnics[pathEthnicKey] === ethnics[2] && ethnics[pathSubEthnicKey] === ethnics[1]

        ethnic = pathEthnic

        if (isEuropeanSubethnicPage) {
          ethnic = pathSubEthnicEthnic
        }
      }
    }

    Object.values(ethnics).forEach(item => {
      if (item === subEthnic) {
        subEthnic = ''
      }
    })
  }

  setEthnics()

  PERSONS.map((item) => {
    if (keyword && keyword.includes(item.key)) {
      person = item.text
    }
  })

  PRODUCT.map((item) => {
    if (keyword && keyword.includes(item.key)) {
      product = item.text
    }
  })

  ACTIONS.map((item) => {
    if (keyword && keyword.includes(item.key)) {
      action = item.text
    }
  })

  const updateValuesInDOM = () => {
    const ethnicArray = document.querySelectorAll('.content-load_ethnic')
    const subEthnicArray = document.querySelectorAll('.content-load_subethnic')
    const personArray = document.querySelectorAll('.content-load_person')
    const productArray = document.querySelectorAll('.content-load_product')
    const actionArray = document.querySelectorAll('.content-load_action')

    if (ethnicArray.length) {
      ethnicArray.forEach((item) => {
        item.textContent = ethnic
      })
    }

    if (personArray.length) {
      personArray.forEach((item) => {
        item.textContent = isMaleSite ? malePerson : person
      })
    }

    if (productArray.length) {
      productArray.forEach((item) => {
        item.textContent = product
      })
    }

    if (actionArray.length) {
      actionArray.forEach((item) => {
        item.textContent = action
      })
    }

    if (subEthnicArray.length) {
      subEthnicArray.forEach((item) => {
        item.textContent = subEthnic
      })
    }
  }

  setTimeout(() => {
    const subEthnic = document.getElementById('subEthnic');

    if (subEthnic && subEthnic.textContent.trim().length === 0) {
      subEthnic.style.display='none'
    }
  }, 0)

  updateValuesInDOM()
}
