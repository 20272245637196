export default function faq () {

    //BEGIN
    // $(".faq__item-title").on("click", function (e) {
    //     e.preventDefault();
    //     var $this = $(this);
    //
    //     $this.parent().toggleClass("open");
    //     $this.next().slideToggle();
    // });
    //END
    function accordion (accordionItemSelector, mode) {
        let accordionElements = document.querySelectorAll(accordionItemSelector)
        let openedElement
        for(let i = 0; i < accordionElements.length; i++) {

            if (accordionElements[i].classList.contains('open')) {
                openedElement = accordionElements[i]
            }
            accordionElements[i].addEventListener('click', function (e) {
                if (mode.closeItem) {
                    if (openedElement && openedElement !== e.currentTarget) {

                        openedElement.classList.remove('open')
                    }
                    openedElement = e.currentTarget
                    if (openedElement.classList.contains('open') && e.target.classList.contains(mode.closeTarget)) {
                        openedElement.classList.remove('open')
                    } else {
                        openedElement.classList.add('open')
                    }

                } else {
                    if (openedElement) {
                        openedElement.classList.remove('open')
                    }
                    openedElement = e.currentTarget
                    openedElement.classList.add('open')
                }

            })
        }
    }
    accordion ('.faq__item', {closeItem: false})
}